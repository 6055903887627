export const usersLogin = 'api/v1/auth/login'
export const getAllRoles = 'api/v1/roles/getAllRoles'
export const getAdminList = 'api/v1/user/getAdminList'
export const getClientEntities = 'api/v1/client/get-client-entities'
export const getUserDetails = 'api/v1/user/getUserDetails/'
export const postCreateAdmin = 'api/v1/user/create-admin-user'
export const putUpdateClient = 'api/v1/client/updateClient'
export const postCreateClient = 'api/v1/client/create-client'
export const getClients = 'api/v1/client/getClients'
export const putDeleteClient = 'api/v1/client/deleteClient'
export const putStoreStatus = 'api/v1/client/update-client-entity-status'
export const putUpdateClientEntity = 'api/v1/client/update-client-entity'
export const postCreateClientEntity = 'api/v1/client/create-client-entity'
export const getEntityTypeList = 'api/v1/entity-type/getEntityTypeList'
export const getVenueList = 'api/v1/venue/venue-list'
export const putVenueStatus = 'api/v1/venue/update-venue-status'
export const postCreateVenue = 'api/v1/venue/create-venue'
export const putUpdateVenue = 'api/v1/venue/update-venue'
export const putUpdateAdminDetails = "api/v1/user/update-admin-details"
export const putUpdateAdminRole = "api/v1/user/updateAdminRole"
export const putUpdateAdminStatus = "api/v1/user/updateAdminStatus"
export const getShopType = "api/v1/shop-type"
export const getStarRating = "api/v1/star-rating"
export const getBriefJson =  "api/v1/brief-json"
export const putUpdateEditors =  "api/v1/client/update-editors"

