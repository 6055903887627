import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Button, TextField } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { get, post, put } from '../../apiService/apiUtils.tsx'
import { toast } from 'react-toastify'
import { ApiPutBriefJson ,ApiPostBriefJson } from '../../apiService/Core/Apicall.tsx'
interface AddOrUpdateBriefJsonProps {
  onSubmit?: (values: any) => void
  mode: 'add' | 'update'
}

const AddOrUpdateBriefJson: React.FC<AddOrUpdateBriefJsonProps> = ({
  onSubmit
}) => {
  debugger
  const location = useLocation()
  const briefEntity = location?.state?.brief
  console.log(briefEntity);

  const navigate = useNavigate()
  const { mode, id } = useParams<{ mode: 'add' | 'update'; id?: string }>()

  const [formValues, setFormValues] = useState({
    title: '',
    minWord: 0,
    maxWord: 0,
    briefJson: ''
  })

  useEffect(() => {
    if (mode === 'update' && briefEntity) {
      setFormValues({
        title: briefEntity.briefJsonTitle || '',
        minWord: briefEntity.minNoOfWords || 0,
        maxWord: briefEntity.maxNoOfWords || 0,
        briefJson: briefEntity?.briefJsonDescription || ''
      })
    }
  }, [mode, briefEntity])

  const handleAddBrief = async (values: any) => {
    const FormData = {
      briefJsonTitle: values.title,
      minNoOfWords: values.minWord,
      maxNoOfWords: values.maxWord,
      briefJsonDescription: values.briefJson,
      createdBy:"11",
    }
    try {
      await ApiPostBriefJson(FormData)
      toast.success('Brief added successfully!')
      navigate(-1)
    } catch (error) {
      console.error('Error adding brief:', error)
      toast.error('Failed to add brief')
    }
  }

  const handleUpdateBrief = async (values: any) => {
    const FormData = {
      briefJsonId: briefEntity?.briefJsonId,
      briefJsonTitle: values.title,
      minNoOfWords: values.minWord,
      maxNoOfWords: values.maxWord,
      briefJsonDescription: values.briefJson,
      modifiedBy: '11'
    }
    try {
      console.log('Brief Json Update', FormData);
      await ApiPutBriefJson(FormData)
      toast.success('Brief updated successfully!')
      navigate(-1)
    } catch (error) {
      console.error('Error updating brief:', error)
      toast.error('Failed to update brief')
    }
  }

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    minWord: Yup.number()
      .min(0, 'Minimum words must be 0 or greater')
      .required('Minimum words are required'),
    maxWord: Yup.number()
      .min(
        Yup.ref('minWord'),
        'Maximum words must be greater than minimum words'
      )
      .required('Maximum words are required'),
    briefJson: Yup.string().required('Brief JSON is required')
  })

  const handleSubmit = (values: any) => {
    if (mode === 'add') {
      handleAddBrief(values)
    } else {
      handleUpdateBrief(values)
    }
  }

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form style={{ background: 'white', padding: '30px' }}>
          <h1 className='common-add'>
            {mode === 'add' ? 'Add New Brief Json' : 'Update Brief Json'}
          </h1>
          <div style={{ padding: '20px', width: '100%', paddingBottom: '0px' }}>
            {[
              { label: 'Title', name: 'title', type: 'text' },
              { label: 'Min. Words', name: 'minWord', type: 'number' },
              { label: 'Max. Words', name: 'maxWord', type: 'number' },
              { label: 'Description', name: 'briefJson', type: 'textarea' }
            ].map(({ label, name, type }) => (
              <div
                key={name}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px',
                  width: '100%'
                }}
              >
                <label
                  htmlFor={name}
                  style={{
                    marginRight: '10px',
                    minWidth: '200px',
                    textAlign: 'right'
                  }}
                >
                  {label}:
                </label>
                {type === 'textarea' ? (
                  <Field
                    as='textarea'
                    id={name}
                    name={name}
                    style={{ width: '100%', height: '80px' }}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <Field
                    type={type}
                    id={name}
                    name={name}
                    style={{ width: 'calc(100% - 220px)', padding: '5px' }}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
              </div>
            ))}
          </div>
          <div className='text-center'>
            <Button
              style={{ padding: '9px 70px' }}
              type='submit'
              variant='contained'
              color='primary'
              className='table-row-background'
            >
              {mode === 'add' ? 'Add Brief' : 'Update Brief'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddOrUpdateBriefJson
