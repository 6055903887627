import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { ApiGetClients ,ApiPutDeleteClient} from "../../apiService/Core/Apicall.tsx";

const ManageAdmin: React.FC = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState<any>([])
  const addOrUpdateClient = (id?: any) => {
    if (id?.ClientID) {
      navigate(`/admin/clients-update/update/${id?.ClientID}`, {
        state: { clients: id },
      });
    } else {
      navigate("/admin/clients-update/add");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleGetApiCall();
    };
    fetchData();
    return () => {};
  }, []);
  const handleGetApiCall = async () => {
    try {
      const response: any = await ApiGetClients();
      setClients(response.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleDelete = async (clientId: number) => {
    try {
      const response: any = await ApiPutDeleteClient(clientId);
      if (response.success) {
        setClients(
          clients.filter((client: any) => client.ClientID !== clientId)
        );
        toast.success('Client deleted successfully!')
      }
    } catch (error) {
      console.error("Error during delete API call:", error);
    }
  };

  return (
    <div className="manage-admin-container">
      <h1 className="common-manage-h1">Manage Clients</h1>
      <div className="add-button common-manage-button">
        <Button
          variant="contained"
          onClick={() => addOrUpdateClient()}
          className="table-row-background "
        >
          Add New Clients
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow className="table-row-background">
            <TableCell style={{ color: "#fff" }}>Client Code</TableCell>
            <TableCell style={{ color: "#fff" }}> Client Name</TableCell>
            <TableCell style={{ color: "#fff" }}>Owner Name</TableCell>
            <TableCell style={{ color: "#fff" }}>Owner Email ID</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.ClientCode}</TableCell>
              <TableCell>{user.ClientName}</TableCell>
              <TableCell>{user.OwnerName}</TableCell>
              <TableCell>{user.OwnerEmailID}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => addOrUpdateClient(user)}
                  className="table-row-background"
                >
                  Edit
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  className="delete-transparent"
                  onClick={() => handleDelete(user.ClientID)}
                >
                  <DeleteIcon className="delete-icon" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ManageAdmin;
