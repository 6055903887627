import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Pages/Login/Login.tsx";
import ProtectedRoute from "./ProtectedRoutes.tsx";
import Unauthorized from "./UnAuthorized.tsx";
import AdminDashboard from "../admin/AdminDashboard/AdminDashboard.tsx";
import ManageAdmin from "../admin/ManageAdmin/ManageAdmin.tsx"; // Import ManageAdmin component
import Home from "../Pages/Home/Home.tsx";
import Layout from "../Components/Layout/Layout.tsx";
import ShopperDashboard from "../shopper/ShopperDashboard/ShopperDashboard.tsx";
import AreaManagerDashboard from "../areaManager/AreaManagerDashboard/AreaManagerDashboard.tsx";
import VenueAdminDashboard from "../venueAdmin/venueAdminDashboard/VenueAdminDashboard.tsx";
import EditorDashboard from "../editor/editorDashboard/editorDashboard.jsx";
import EditAccessGroup from "../admin/ManageAdmin/EditAccessGroup.tsx";
import AddOrUpdate from "../admin/ManageAdmin/AddOrUpdateAdmin.tsx";
import ManageStore from "../admin/ManageStore/ManageStore.tsx";
import AddOrUpdateStore from "../admin/ManageStore/AddOrUpdateStore.tsx";
import ManageClients from "../admin/ManageClients/ManageClients.tsx";
import AddOrUpdateClients from "../admin/ManageClients/AddOrUpdateClients.tsx";
import ManageVenue from "../admin/ManageVenue/ManageVenue.tsx";
import AddOrUpdateVenue from "../admin/ManageVenue/AddOrUpdateVenue.tsx";
import ImportExportQuestions from "../admin/ImportExportQuestions/ImportExportQuestions.tsx";
import ImportExportQuestionsManager from "../admin/ImportExportQuestionsManager/ImporttExportQuestionsManager.tsx";
import AreaManager from "../admin/AreaManager/AreaManager.tsx";
import AddOrUpdateAreaManager from "../admin/AreaManager/AddOrUpdateAreaManager.tsx";
import VenueMap from "../admin/ManageVenue/VenueMap.tsx";
import BriefJson from "../admin/BriefJson/BriefJson.tsx";
import AddOrUpdateBriefJson from "../admin/BriefJson/AddOrUpdateBriefJson.tsx";
import ViewEditorsBusiness from "../admin/ManageStore/ViewEditorsBusiness.tsx";
import CreateShopper from "../shopper/createShopper.tsx";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/venue-map" element={<VenueMap />} />
      <Route
        path="/createShopper"
        element={
          <Layout>
            <CreateShopper />
          </Layout>
        }
      />
      {/* Protected Route for Admin Dashboard */}
      <Route
        path="/admin/dashboard"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <AdminDashboard />
            </Layout>
          </ProtectedRoute>
        }
      />

      {/* Protected Route for Manage Admin */}
      <Route
        path="/admin/manage-admin"
        element={
          <ProtectedRoute allowedRoles={["1"]}>
            <Layout>
              <ManageAdmin /> {/* Use ManageAdmin component here */}
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/manage-store"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <ManageStore />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/manage-clients"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <ManageClients />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/venue-managers"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <ManageVenue />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/import-export"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <ImportExportQuestions />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/import-export-manager"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <ImportExportQuestionsManager />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/brief-json"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <BriefJson />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/area-managers"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <AreaManager />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/clients-update/:mode/:id?"
        element={
          <ProtectedRoute allowedRoles={["1"]}>
            <Layout>
              <AddOrUpdateClients
                onSubmit={function (values: any): void {}}
                mode={"add"}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/add-or-update/:mode/:id?"
        element={
          <ProtectedRoute allowedRoles={["1"]}>
            <Layout>
              <AddOrUpdate
                onSubmit={function (values: any): void {}}
                mode={"add"}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/add-or-update-store/:mode/:id?"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <AddOrUpdateStore
                onSubmit={function (values: any): void {}}
                mode={"add"}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/add-or-update-venue/:mode/:id?"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <AddOrUpdateVenue
                onSubmit={function (values: any): void {}}
                mode={"add"}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/add-or-update-areamanager/:mode/:id?"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <AddOrUpdateAreaManager
                onSubmit={function (values: any): void {}}
                mode={"add"}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/add-or-update-briefjson/:mode/:id?"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <AddOrUpdateBriefJson
                onSubmit={function (values: any): void {}}
                mode={"add"}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/edit-access-group"
        element={
          <ProtectedRoute allowedRoles={["1"]}>
            <Layout>
              <EditAccessGroup />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/admin/view-editor-business"
        element={
          <ProtectedRoute allowedRoles={["1", "2"]}>
            <Layout>
              <ViewEditorsBusiness
                onSubmit={function (values: any): void {
                  throw new Error("Function not implemented.");
                }}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      {/* Protected Route for Shopper Dashboard */}
      <Route
        path="/shopper/dashboard"
        element={
          <ProtectedRoute allowedRoles={["1", "4"]}>
            <Layout>
              <ShopperDashboard />
            </Layout>
          </ProtectedRoute>
        }
      />

      {/* Protected Route for Area Manager Dashboard */}
      <Route
        path="/areaManager/dashboard"
        element={
          <ProtectedRoute allowedRoles={["1", "5"]}>
            <Layout>
              <AreaManagerDashboard />
            </Layout>
          </ProtectedRoute>
        }
      />

      {/* Protected Route for Venue Admin Dashboard */}
      <Route
        path="/venueAdmin/dashboard"
        element={
          <ProtectedRoute allowedRoles={["1", "6"]}>
            <Layout>
              <VenueAdminDashboard />
            </Layout>
          </ProtectedRoute>
        }
      />

      {/* Protected Route for Editor Dashboard */}
      <Route
        path="/editor/dashboard"
        element={
          <ProtectedRoute allowedRoles={["3"]}>
            <Layout>
              <EditorDashboard />
            </Layout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
