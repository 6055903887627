import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { Button, Autocomplete, TextField } from '@mui/material'
import * as Yup from 'yup'
import {
  ApiGetAdminList,
  ApiGetClientEntities,
  ApiPutUpdateEditors
} from '../../apiService/Core/Apicall.tsx'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

interface ViewEditorsBusinessProps {
  onSubmit: (values: any) => void
}

interface Editor {
  label: string
  value: string
}

interface Business {
  label: string
  value: string
}

const ViewEditorsBusiness: React.FC<ViewEditorsBusinessProps> = ({
  onSubmit
}) => {
  const [businesses, setBusinesses] = useState<Business[]>([])
  const [editors, setEditors] = useState<Editor[]>([])
  const location = useLocation()
  const navigate = useNavigate()
  const store = location?.state?.store
  const [editorIds, setEditorIds] = useState(store?.editorIds || [])
  const [clientEntityId, setClientEntityId] = useState(store?.clientEntityId || '')

  useEffect(() => {
    const handleGetEditType = async () => {
      try {
        const response: any = await ApiGetAdminList('')
        const transformedData = response.data.map((item: any) => ({
          value: item.userId,
          label: item.displayName
        }))
        setEditors(transformedData)
      } catch (error) {
        console.error('Error during API call:', error)
      }
    }

    handleGetEditType()
    handleGetClientList()
  }, [])

  const handleGetClientList = async () => {
    const data = ''
    try {
      const response: any = await ApiGetClientEntities(data)
      const transformedData = response.data.map((item: any) => ({
        value: item.clientEntityId,
        label: item.entityName
      }))
      setBusinesses(transformedData)
      console.log('Client entities:', response.data)
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  const validationSchema = Yup.object({
    business: Yup.string().required('Please select a business'),
    editors: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one editor must be selected')
  })

  const handleSubmit = async (values: any) => {
    // Convert the selected editors (array) to a comma-separated string only when submitting
    const commaSeparated = values.editors.length > 0 ? values.editors.join(',') : ''

    console.log('Editor IDs (comma separated):', commaSeparated)

    const data = {
      clientEntityId: values.business,
      editorIds: commaSeparated, // Send as a comma-separated string for the API
      createdBy: 11,
      modifiedBy: 11
    }

    try {
      const res = await ApiPutUpdateEditors(data)
      toast.success('View Editors updated successfully!')
      navigate(-1)
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  return (
    <Formik
      initialValues={{
        business: clientEntityId,
        editors: editorIds
      }}
      validationSchema={validationSchema}
      onSubmit={values => {
        handleSubmit(values)
      }}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form style={{ background: 'white', padding: '30px' }}>
          <h1 className='common-add'>View Editors for Business</h1>

          {/* Business Selector */}
          <div className='form-group'>
            <Autocomplete
              options={businesses}
              value={
                businesses.find(option => option.value === values.business) ||
                null
              }
              getOptionLabel={option => option.label}
              onChange={(event, newValue) => {
                setFieldValue('business', newValue ? newValue.value : '')
              }}
              disabled
              renderInput={params => (
                <TextField
                  {...params}
                  label='Select Business'
                  fullWidth
                  error={touched.business && Boolean(errors.business)}
                  helperText={touched.business && errors.business}
                />
              )}
            />
          </div>

          {/* Editors Multi-Select */}
          <div className='form-group' style={{ marginTop: '20px' }}>
            <Autocomplete
              multiple
              options={editors}
              getOptionLabel={option => option.label}
              value={editors.filter(editor =>
                values.editors.includes(editor.value)
              )}
              onChange={(event, value) => {
                const selectedValues = value.map(editor => editor.value)
                setFieldValue('editors', selectedValues) // Update editors array
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Editors'
                  fullWidth
                  error={touched.editors && Boolean(errors.editors)}
                  helperText={touched.editors && errors.editors}
                />
              )}
            />
          </div>

          {/* Submit Button */}
          <div className='text-center' style={{ marginTop: '30px' }}>
            <Button
              style={{ padding: '9px 70px' }}
              type='submit'
              variant='contained'
              color='primary'
              className='table-row-background'
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ViewEditorsBusiness
