import React from 'react';

const AreaManagerDashboard: React.FC = () => {
  return (
    
    <div className='Admin_Dashboard'>
      <h1>AreaManager Dashboard</h1>
    </div>
  );
};

export default AreaManagerDashboard;
