import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";

const ImportExportQuestionsManager: React.FC = () => {
  const [selectedVenue, setSelectedVenue] = useState(""); // State for the selected value
  const [venues, setVenues] = useState([]);

  const handleVenueChange = (event) => {
    setSelectedVenue(event.target.value);
  };

  return (
    <div className="manage-admin-container">
      <h1 className="common-manage-h1">Import Export Manager Questions</h1>

      <FormControl
        style={{ width: "200px", marginTop: "20px" }}
        variant="outlined"
      >
        <InputLabel id="select-venue-label">Select Venue</InputLabel>
        <Select
          labelId="select-venue-label"
          id="select-venue"
          value={selectedVenue}
          onChange={handleVenueChange}
          label="Select Venue"
        >
          {venues.length === 0 ? (
            <MenuItem disabled value="">
              No options available
            </MenuItem>
          ) : (
            venues.map((venue) => <MenuItem></MenuItem>)
          )}
        </Select>
      </FormControl>
      <div style={{ display: "flex", gap: "20px", padding: "10px" }}>
        <div
          style={{
            margin: "10px",
            padding: "10px",
            border: "none", // No border
            borderRadius: "5px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Box shadow for depth
            textAlign: "center",
          }}
        >
          <h5>Import Questions</h5>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TextField
              type="file"
              variant="outlined"
              style={{ margin: "10px" }}
            />
            <div
              style={{ display: "flex", gap: "10px", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                className="table-row-background"
                style={{ margin: "10px 0" }}
              >
                Upload
              </Button>
              <Button
                variant="contained"
                className="table-row-background"
                style={{ margin: "10px 0" }}
              >
                Upload to All Venues
              </Button>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: "10px",
            padding: "10px",
            border: "none", // No border
            borderRadius: "5px", // Optional rounded corners
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Box shadow for depth
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            className="table-row-background"
            style={{ margin: "10px" }}
          >
            Export All Questions
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImportExportQuestionsManager;
