import React, { useState, ChangeEvent, FormEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { Images } from '../../global/GloblImages.tsx'
import { ApiUsersLogin } from '../../apiService/Core/Apicall.tsx'
interface LoginErrors {
  username: string
  password: string
}
const Login: React.FC = () => {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errors, setErrors] = useState<LoginErrors>({
    username: '',
    password: ''
  })
  const icons: any = Images()
  const navigate = useNavigate()

  const handleLogin = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()

    const loginData: any = {
      emailId: username,
      password: password
    }

    const newErrors: LoginErrors = { username: '', password: '' }
    if (!username) newErrors.username = 'Please enter username'
    if (!password) newErrors.password = 'Please enter password'

    if (newErrors.username || newErrors.password) {
      setErrors(newErrors)
      return
    }

    try {
      const response: any = await ApiUsersLogin(loginData)
      console.log('API Response (Posts):', response)
      if (response?.userDetails) {
        const user = response.userDetails
        console.log('Login successful:', {
          username: user.username,
          roleId: user.role.roleId
        })
        localStorage.setItem('userRole', user.role.roleId)
        localStorage.setItem('userStatusId', user.userStatus.userStatusId)
        localStorage.setItem('createdBy', user.createdBy)
        localStorage.setItem('user', user)
        const roleRedirects: Record<string, string> = {
          '1': '/admin/dashboard',
          '2': '/admin/dashboard',
          '3': '/editor/dashboard',
          '4': '/shopper/dashboard',
          '5': '/areaManager/dashboard',
          '6': '/venueAdmin/dashboard'
        }

        const redirectUrl = roleRedirects[user.role.roleId] || '/unauthorized'
        navigate(redirectUrl)
      } else {
        setErrors({ ...newErrors, username: 'Invalid username or password' })
      }
    } catch (error) {
      console.error('Error during API call:', error)
      setErrors({
        ...newErrors,
        username: 'Something went wrong. Please try again.'
      })
    }
  }

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ): void => {
    setter(e.target.value)
  }

  return (
    <div
      style={{
        backgroundImage: `url(${icons.LoginBanner})`,
        minHeight: '100%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '100%',
        width: '100%',
        position: 'fixed',
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      <form onSubmit={handleLogin} id='formLogin'>
        <div className='row'>
          <div
            className='col-lg-4 col-md-4 col-sm-12 offset-sm-0 offset-lg-4 p-sm-5'
            style={{ margin: 'auto' }}
          >
            <div className='team-item p-lg-5 p-sm-3'>
              <div style={{ textAlign: 'center' }}>
                <img src={icons.mmLogo} alt='Mystery Management' />
              </div>
              <div className='team-content'>
                <br />
                <h3 className='box-title m-b-20 text-center'>Sign In</h3>
                <br />
                <div className='form-group'>
                  <div className='col-xs-12'>
                    <input
                      name='txtUN'
                      type='text'
                      id='txtUN'
                      className='form-control'
                      placeholder='Email'
                      value={username}
                      onChange={e => handleInputChange(e, setUsername)}
                    />
                    {errors.username && (
                      <span className='text-danger'>{errors.username}</span>
                    )}
                  </div>
                </div>
                <div className='form-group'>
                  <div className='col-xs-12'>
                    <input
                      name='txtPass'
                      type='password'
                      id='txtPass'
                      className='form-control'
                      placeholder='Password'
                      value={password}
                      onChange={e => handleInputChange(e, setPassword)}
                    />
                    {errors.password && (
                      <span className='text-danger'>{errors.password}</span>
                    )}
                  </div>
                </div>
                <div className='form-group text-center m-t-20'>
                  <div className='col-xs-12'>
                    <button
                      type='submit'
                      className='btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light mb-0'
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Login
