import React from 'react';

const ShopperDashboard: React.FC = () => {
  return (
    
    <div className='Admin_Dashboard'>
      <h1>Shopper Dashboard</h1>
    </div>
  );
};

export default ShopperDashboard;
