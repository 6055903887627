import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Autocomplete,
  TextField
} from '@mui/material'
import { data, useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import Switch from '@mui/material/Switch'
import {
  ApiGetClientEntities,
  ApiGetAdminList,
  ApiPutStoreStatus,
  ApiPutUpdateClientEntity
} from '../../apiService/Core/Apicall.tsx'

const ManageStore: React.FC = () => {
  const [adminUsers, setAdminUsers] = useState<any>([])
  const [clientEntityList, setClientEntityList] = useState<any>([])
  const [status, setStatus] = useState({})

  const navigate = useNavigate()

  const handleGetApiCall = async () => {
    const data = {}
    try {
      const response: any = await ApiGetAdminList(data)
      setAdminUsers(response.data)
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  const handleGetClientList = async () => {
    try {
      const response: any = await ApiGetClientEntities(data)
      setClientEntityList(response.data)
      console.log('rse store', response.data)
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  const addOrUpdateStore = (store?: any) => {
    if (store?.clientEntityId) {
      navigate(`/admin/add-or-update-store/update/${store?.clientEntityId}`, {
        state: { clientEntity: store }
      })
    } else {
      navigate('/admin/add-or-update-store/add')
    }
  }

  const navigateToViewEditors = (store?: any) => {
    navigate('/admin/view-editor-business', {
      state: { store }
    })
  }

  const handleChange = async (store: any, newStatus: string) => {
    setStatus(prevStatus => ({
      ...prevStatus,
      [store.clientEntityId.ClientID]: newStatus
    }))

    setClientEntityList(prevList =>
      prevList.map(item =>
        item.clientEntityId === store.clientEntityId
          ? {
              ...item,
              clientEntityStatus: {
                ...item.clientEntityStatus,
                userStatus: newStatus
              }
            }
          : item
      )
    )

    try {
      const body = {
        clientEntityId: store?.clientEntityId,
        status: newStatus
      }
      const response: any = await ApiPutStoreStatus(body)
      handleGetApiCall()
      console.log('Status updated successfully:', response)
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      await handleGetClientList()
      await handleGetApiCall()
    }
    fetchData()
    return () => {}
  }, [])
  const onclickEdit = (value: any, store: any) => {
    setClientEntityList(prevState =>
      prevState.map(item =>
        item.clientEntityId === store.clientEntityId
          ? { ...item, editorId: { ...item.editorId, userName: value?.value } }
          : item
      )
    )
    const FormData = {
      clientEntityId: store.clientEntityId,
      editorId: value.roleId,
      entityName: store.entityName,
      entityCode: store.entityCode
    }
    const res = ApiPutUpdateClientEntity(FormData)
  }
  const typeOptions = adminUsers.map(role => ({
    label: role.userName,
    roleId: role.userId,
    value: role.userName
  }))

  return (
    <div className='manage-admin-container'>
      <h1 className='common-manage-h1'>Manage Business</h1>
      <div className='add-button common-manage-button'>
        <Button
          variant='contained'
          onClick={() => addOrUpdateStore()}
          className='table-row-background '
        >
          Add New Business
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow className='table-row-background'>
            <TableCell style={{ color: '#fff' }}>ID</TableCell>
            <TableCell style={{ color: '#fff' }}>Location Name</TableCell>
            <TableCell style={{ color: '#fff' }}>Code</TableCell>
            <TableCell style={{ color: '#fff' }}>Hotel Type</TableCell>
            <TableCell style={{ color: '#fff' }}>Editor</TableCell>
            <TableCell style={{ color: '#fff' }}>Active</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientEntityList.map(store => (
            <TableRow key={store.clientEntityId}>
              <TableCell>{store.clientEntityId}</TableCell>
              <TableCell>{store.entityName}</TableCell>
              <TableCell>{store.entityCode}</TableCell>
              <TableCell>{store.entityTypeId.entityTypeName}</TableCell>
              <TableCell>
                <Button
                  variant='contained'
                  onClick={() => navigateToViewEditors(store)}
                  className='table-row-background'
                >
                  View Editors
                </Button>
              </TableCell>
              <TableCell>
                <div className='switch-admin'>
                  <Switch
                    checked={store?.clientEntityStatus?.userStatus === 'active'}
                    onChange={e => {
                      const newStatus = e.target.checked ? 'active' : 'inActive'
                      handleChange(store, newStatus) // Pass the store and the new status
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
              </TableCell>
              <TableCell>
                <Button
                  variant='contained'
                  onClick={() => addOrUpdateStore(store)}
                  className='table-row-background'
                >
                  Edit
                </Button>
              </TableCell>
              <TableCell>
                <Button variant='contained' className='delete-transparent'>
                  <DeleteIcon className='delete-icon' />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default ManageStore
