import React from 'react';

const EditorDashboard = () => {
  return (
    
    <div className='Admin_Dashboard'>
      <h1>Editor Dashboard</h1>
    </div>
  );
};

export default EditorDashboard;
