import React from "react";
import Header from "../Header/Header.tsx"; // Adjust the import path as needed
import Footer from "../Footer/Footer.tsx"; // Adjust the import path as needed

// The Layout component will accept children as a prop, ensuring that it's typed properly
const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div>
      <Header />
        <div style={{paddingTop: '20px',paddingBottom:"100px"}}>
          <main>{children}</main>
        </div>
      <Footer />
    </div>
  );
};

export default Layout;
