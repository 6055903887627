import React, { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import { get } from '../../apiService/apiUtils.tsx'

const AreaManager: React.FC = () => {
  const AreaManagers = [
    { id: 1, AreaManager: 'Admin 1', UserName: 'admin', Password: 'password', Email: 'areamanager@example.com' },
    { id: 2, AreaManager: 'Admin 1', UserName: 'admin', Password: 'password', Email: 'areamanager@example.com' }
  ]

  const navigate = useNavigate()

  const addOrUpdateAreaManager = (id?: any) => {
    if (id) {
      navigate(`/admin/add-or-update-areamanager/update/${id}`)
    } else {
      navigate('/admin/add-or-update-areamanager/add')
    }
  }

//   useEffect(() => {
//     const fetchData = async () => {
//       await handleGetApiCall()
//     }
//     fetchData()
//     return () => {}
//   }, [])
//   const handleGetApiCall = async () => {
//     try {
//       const response: any = await get('api/v1/client/getClients')
//       setClients(response.data)
//     } catch (error) {
//       console.error('Error during API call:', error)
//     }
//   }

  return (
    <div className='manage-admin-container'>
      <h1 className='common-manage-h1' >Area Manager</h1>
      <div className='add-button common-manage-button'>
        <Button
          variant='contained'
          onClick={() => addOrUpdateAreaManager()}
          className='table-row-background '
        >
          Add New Area Manager
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow className='table-row-background'>
            <TableCell></TableCell>
            <TableCell style={{ color: '#fff' }}>Area Manager</TableCell>
            <TableCell style={{ color: '#fff' }}>UserName</TableCell>
            <TableCell style={{ color: '#fff' }}>Password</TableCell>
            <TableCell style={{ color: '#fff' }}>Email</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {AreaManagers.map(user => (
            <TableRow key={user.id}>
              <TableCell>
                <Button variant='contained' className='delete-transparent'>
                  <DeleteIcon className='delete-icon' />
                </Button>
              </TableCell>
              <TableCell>{user.AreaManager}</TableCell>
              <TableCell>{user.UserName}</TableCell>
              <TableCell>{user.Password}</TableCell>
              <TableCell>{user.Email}</TableCell>
              <TableCell>
                <Button
                  variant='contained'
                  onClick={() => addOrUpdateAreaManager(user.id)}
                  className='table-row-background'
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default AreaManager
