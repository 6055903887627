import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

const EditAccessGroup: React.FC = () => {
  const adminUsers = [
    { id: 1, name: 'Admin 1', email: 'admin1@example.com' },
    { id: 2, name: 'Admin 2', email: 'admin2@example.com' },
  ];

  return (
    <div className="manage-admin-container">
        <h1>Editor Access Group</h1>
        <TableContainer component={Paper}>
        <Table>
            <TableHead>
            <TableRow className='table-row-background'>
                <TableCell style={{color:"#fff"}}></TableCell>
                <TableCell style={{color:"#fff"}}>ID</TableCell>
                <TableCell style={{color:"#fff"}}>Location Name</TableCell>
                <TableCell style={{color:"#fff"}}>Code</TableCell>
                <TableCell style={{color:"#fff"}}>Hotel Type</TableCell>
                <TableCell style={{color:"#fff"}}>Active</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {adminUsers.map((user) => (
                <TableRow key={user.id}>
                <TableCell>
                        
                    </TableCell>
                    <TableCell>
                        
                    </TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    </div>
  );
};

export default EditAccessGroup;
