import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Box,
  Grid,
} from "@mui/material";

// Validation Schema
const validationSchema = Yup.object({
  abn: Yup.string().required("ABN is required"),
  username: Yup.string().required("Username is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Must include at least one uppercase letter")
    .matches(/[0-9]/, "Must include at least one number")
    .matches(/[!@#$%^&*]/, "Must include at least one special character")
    .required("Password is required"),
  bankName: Yup.string().required("Bank Name is required"),
  bsb: Yup.string().required("BSB is required"),
  accountNo: Yup.string().required("Account Number is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  phone: Yup.string().required("Phone/Mobile is required"),
  address: Yup.string().required("Address is required"),
  region: Yup.string().required("Region is required"),
  state: Yup.string().required("State is required"),
  postcode: Yup.string().required("Postcode is required"),
  dob: Yup.date().required("Date of Birth is required"),
  gender: Yup.string().required("Gender is required"),
  employmentStatus: Yup.string().required("Employment Status is required"),
  acceptTerms: Yup.boolean().oneOf(
    [true],
    "You must accept the Terms and Conditions"
  ),
  workForAldi: Yup.boolean(),
  workForBlooms: Yup.boolean(),
  workForHotel: Yup.string(),
  driverLicense: Yup.mixed().required("Driver's License/Photo ID is required"),
  profilePicture: Yup.mixed().required("Profile Picture is required"),
  availability: Yup.object().shape({
    monFri: Yup.boolean(),
    saturday: Yup.boolean(),
    sunday: Yup.boolean(),
    nights: Yup.boolean(),
  }),
});

const CreateShopper: React.FC = () => {
  return (
    <Formik
      initialValues={{
        abn: "",
        username: "",
        password: "",
        bankName: "",
        bsb: "",
        accountNo: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        region: "",
        state: "",
        postcode: "",
        dob: "",
        gender: "",
        employmentStatus: "",
        acceptTerms: false,
        workForAldi: false,
        workForBlooms: false,
        workForHotel: "",
        driverLicense: null,
        profilePicture: null,
        availability: {
          monFri: false,
          saturday: false,
          sunday: false,
          nights: false,
        },
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form>
          <Box
            sx={{
              maxWidth: 800,
              margin: "auto",
              padding: 4,
              backgroundColor: "#f9f9f9",
              borderRadius: 2,
              boxShadow: 2,
            }}
          >
            <Typography variant="h4" align="center" gutterBottom>
              Create Shopper
            </Typography>

            {/* ABN Details */}
            <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
              ABN Details
            </Typography>
            <Field
              name="abn"
              as={TextField}
              label="ABN"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={touched.abn && Boolean(errors.abn)}
              helperText={touched.abn && errors.abn}
            />

            {/* Login Details */}
            <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
              Login Details
            </Typography>
            <Field
              name="username"
              as={TextField}
              label="Username"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username}
            />
            <Field
              name="password"
              as={TextField}
              label="Password"
              type="password"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
            />

            {/* Bank Details */}
            <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
              Bank Details
            </Typography>
            <Field
              name="bankName"
              as={TextField}
              label="Bank Name"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={touched.bankName && Boolean(errors.bankName)}
              helperText={touched.bankName && errors.bankName}
            />
            <Field
              name="bsb"
              as={TextField}
              label="BSB"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={touched.bsb && Boolean(errors.bsb)}
              helperText={touched.bsb && errors.bsb}
            />
            <Field
              name="accountNo"
              as={TextField}
              label="Account No"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={touched.accountNo && Boolean(errors.accountNo)}
              helperText={touched.accountNo && errors.accountNo}
            />

            {/* Personal Details */}
            <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
              Personal Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  name="firstName"
                  as={TextField}
                  label="First Name"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="lastName"
                  as={TextField}
                  label="Last Name"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
            </Grid>
            <Field
              name="email"
              as={TextField}
              label="Email"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
            <Field
              name="phone"
              as={TextField}
              label="Phone / Mobile"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={touched.phone && Boolean(errors.phone)}
              helperText={touched.phone && errors.phone}
            />
            <Field
              name="address"
              as={TextField}
              label="Address"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={touched.address && Boolean(errors.address)}
              helperText={touched.address && errors.address}
            />

            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  name="region"
                  as={TextField}
                  label="Region"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  error={touched.region && Boolean(errors.region)}
                  helperText={touched.region && errors.region}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="state"
                  as={TextField}
                  label="State"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state && errors.state}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="postcode"
                  as={TextField}
                  label="Postcode"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  error={touched.postcode && Boolean(errors.postcode)}
                  helperText={touched.postcode && errors.postcode}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Field
                  name="dob"
                  as={TextField}
                  label="Date of Birth"
                  type="date"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  InputLabelProps={{ shrink: true }}
                  error={touched.dob && Boolean(errors.dob)}
                  helperText={touched.dob && errors.dob}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="gender"
                  as={TextField}
                  select
                  label="Gender"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  error={touched.gender && Boolean(errors.gender)}
                  helperText={touched.gender && errors.gender}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Field>
              </Grid>
            </Grid>

            <Field
              name="employmentStatus"
              as={TextField}
              select
              label="Employment Status"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={
                touched.employmentStatus && Boolean(errors.employmentStatus)
              }
              helperText={touched.employmentStatus && errors.employmentStatus}
            >
              <MenuItem value="Full-time">Full-time</MenuItem>
              <MenuItem value="Part-time">Part-time</MenuItem>
              <MenuItem value="Casual">Casual</MenuItem>
            </Field>

            {/* Availability */}
            <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
              Availability
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <FormControlLabel
                control={<Field name="availability.monFri" as={Checkbox} />}
                label="Mon-Fri"
              />
              <FormControlLabel
                control={<Field name="availability.saturday" as={Checkbox} />}
                label="Saturday"
              />
              <FormControlLabel
                control={<Field name="availability.sunday" as={Checkbox} />}
                label="Sunday"
              />
              <FormControlLabel
                control={<Field name="availability.nights" as={Checkbox} />}
                label="Nights"
              />
            </Box>

            {/* Other Details */}
            <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
              Other Details
            </Typography>
            <FormControlLabel
              control={<Field name="workForAldi" as={Checkbox} />}
              label="Do you work for ALDI?"
            />
            <FormControlLabel
              control={<Field name="workForBlooms" as={Checkbox} />}
              label="Do you work for Blooms the Chemist?"
            />
            <Field
              name="workForHotel"
              as={TextField}
              label="Do you work for a hotel/pub chain? If so, which one?"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={touched.workForHotel && Boolean(errors.workForHotel)}
              helperText={touched.workForHotel && errors.workForHotel}
            />

            {/* Document Details */}
            <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
              Document Details
            </Typography>
            <Field
              name="driverLicense"
              as={TextField}
              type="file"
              fullWidth
              sx={{ marginBottom: 2 }}
              InputLabelProps={{ shrink: true }}
              label="Scan Driver's Licence or Photo ID Here:"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue("driverLicense", event.currentTarget.files?.[0])
              }
            />
            <Field
              name="profilePicture"
              as={TextField}
              type="file"
              fullWidth
              sx={{ marginBottom: 2 }}
              InputLabelProps={{ shrink: true }}
              label="Profile Picture:"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFieldValue("profilePicture", event.currentTarget.files?.[0])
              }
            />

            {/* Terms and Conditions */}
            <FormControlLabel
              control={<Field name="acceptTerms" as={Checkbox} />}
              label="I accept the Terms and Conditions"
            />
            {touched.acceptTerms && Boolean(errors.acceptTerms) && (
              <Typography color="error">{errors.acceptTerms}</Typography>
            )}

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              className="table-row-background "
              color="primary"
              fullWidth
            >
              Submit
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default CreateShopper;
