import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Place } from '@mui/icons-material';

const GOOGLE_MAPS_API_KEY = 'AIzaSyAA9RpZwcoyBjSYYLCgd6BIZkH1d-t1LCw'; // Replace with your actual API key

const VenueMap: React.FC = () => {
  const [searchParams] = useSearchParams();
  const venueId = searchParams.get('id'); // Get venue ID from URL parameters
  const initialLat = parseFloat(searchParams.get('lat') || '0');
  const initialLng = parseFloat(searchParams.get('lng') || '0');

  const [markerPosition, setMarkerPosition] = useState({
    lat: initialLat || -25.2744,
    lng: initialLng || 133.7751,
  });

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setMarkerPosition({
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      });
    }
  };

  const handleSaveLocation = () => {
    alert(`Venue ID: ${venueId}, Location: ${markerPosition.lat}, ${markerPosition.lng}`);
    // Send the location back to the server or parent component
  };

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={['places']}>
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '90%' }}
          center={markerPosition}
          zoom={8}
          onClick={handleMapClick}
        >
          <Marker position={markerPosition} />
        </GoogleMap>
      </LoadScript>
      <div style={{ textAlign: 'center', padding: '10px' }}>
        <Button variant="contained" onClick={handleSaveLocation}>
          Save Location
        </Button>
      </div>
    </div>
  );
};

export default VenueMap;
