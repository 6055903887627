import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Button, Autocomplete, TextField } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import {
  ApiPutUpdateClientEntity,
  ApiPostCreateClientEntity,
  ApiGetClients,
  ApiGetEntityTypeList,
  ApiGetBriefJson
} from '../../apiService/Core/Apicall.tsx'
interface AddOrUpdateStoreProps {
  initialValues?: {
    locationName: string
    code: string
    clientName: string
    clientPhone: string
    clientAddress: string
    clientState: string
    clientPostCode: string
    clientInvoiceEmail: string
    fileName: string
    pdfName: string
    templateURL: string
    minWords: number
    maxWords: number
    showProductOfMonth: boolean
    showExtraFields: boolean
    hotelType: string
    ownerEmail: string
    teamBCCEmail: string
    reportMessage: string
    briefJson: string
    starRatings: number
    maxImages: number
    minImages: number
    hideInvoicePanel: boolean
  }
  mode: 'add' | 'update'
  onSubmit: (values: any) => void
}

const AddOrUpdateStore: React.FC<AddOrUpdateStoreProps> = ({
  initialValues = {
    locationName: '',
    entityCode: '',
    clientName: '',
    clientPhone: '',
    clientAddress: '',
    clientState: '',
    clientPostCode: '',
    clientInvoiceEmail: '',
    fileName: '',
    pdfName: '',
    templateURL: '',
    minWords: 0,
    maxWords: 0,
    showProductOfMonth: false,
    showExtraFields: false,
    hotelType: '',
    ownerEmail: '',
    teamBCCEmail: '',
    reportMessage: '',
    briefJson: '',
    starRatings: '',
    maxImages: 0,
    minImages: 0,
    hideInvoicePanel: false
  },
  onSubmit
}) => {
  const { mode } = useParams<{ mode: 'add' | 'update' }>()

  const location = useLocation()
  const clientEntity = location?.state?.clientEntity
  const [formValues, setFormValues] = useState(initialValues)
  const [clients, setClients] = useState([])
  const [entityType, setEntityType] = useState<any[]>([])

  const navigate = useNavigate()

  useEffect(() => {
    if (mode === 'update' && clientEntity) {
      console.log(`Fetching data for admin with ID: ${clientEntity}`)
      setFormValues({
        locationName: clientEntity.entityName || '',
        entityCode: clientEntity.entityCode || '',
        clientName: clientEntity.clientId.ClientID || '',
        clientPhone: clientEntity.contactNo || '',
        clientAddress: clientEntity.address || '',
        clientState: clientEntity.state || '',
        clientPostCode: clientEntity.postalCode || '',
        clientInvoiceEmail: clientEntity.emailId || '',
        fileName: clientEntity.fileName || '',
        pdfName: clientEntity.pdfName || '',
        templateURL: clientEntity.templateURL || '',
        minWords: clientEntity.minNoofWords || 0,
        maxWords: clientEntity.maxNoofWords || 0,
        showProductOfMonth: clientEntity.showPom || false,
        showExtraFields: clientEntity.extraFields || false,
        hotelType: clientEntity.entityTypeId.entityTypeId || '',
        ownerEmail: clientEntity.ownerEmail || '',
        teamBCCEmail: clientEntity.bccEmailId || '',
        reportMessage: clientEntity.reportMessage || '',
        briefJson: clientEntity.briefJson || '',
        starRatings: clientEntity.ratingJson || '',
        maxImages: clientEntity.maxNoOfImages || 0,
        minImages: clientEntity.minNoOfImages || 0,
        hideInvoicePanel: clientEntity.invoicePanel || false
      })
    }
  }, [mode, clientEntity])

  useEffect(() => {
    if (clientEntity) {
      console.log('response getUserDetails', clientEntity?.entityName)
    }
    handleGetApiCallClients()
    handleGetApiCallEntityType()
    handleGetEditType()
  }, [clientEntity, mode])

  const handleGetApiCallClients = async () => {
    try {
      const response: any = await ApiGetClients()
      console.log('response roles', response.data)
      setClients(
        response.data.map((client: any) => ({
          label: client.ClientName,
          value: client.ClientID
        }))
      )
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  // Fetch entity type list from the API
  const handleGetApiCallEntityType = async () => {
    try {
      const response: any = await ApiGetEntityTypeList()
      console.log('response roles', response.data)

      // Transform the API response to the required format
      const transformedData = response.data.map((item: any) => ({
        value: item.entityTypeId,
        label: item.entityTypeName
      }))

      // Set the transformed data into entityType state
      setEntityType(transformedData)
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  // Function to handle adding a new store
  const handleAddStore = async (values: any) => {
    const FormData = {
      entityName: values.locationName,
      entityCode: values.entityCode,
      clientId: values.clientName,
      contactNo: values.clientPhone,
      emailId: values.clientInvoiceEmail,
      address: values.clientAddress,
      city: '',
      state: values.clientState,
      postalCode: values.clientPostCode,
      country: '',
      lat: '',
      long: '',
      fileName: values.fileName,
      pdfName: values.pdfName,
      minNoofWords: values.minWords,
      maxNoofWords: values.maxWords,
      showPom: values.showProductOfMonth,
      extraFields: values.showExtraFields,
      entityTypeId: values.hotelType,
      bccEmailId: values.teamBCCEmail,
      briefJson: values.briefJson,
      ratingJson: values.starRatings,
      maxNoOfImages: values.maxImages,
      minNoOfImages: values.minImages,
      invoicePanel: values.hideInvoicePanel,
      createdBy: 1
    }
    try {
      console.log(FormData)
      const response = await ApiPostCreateClientEntity(FormData)
      console.log('New store added:', response)
      toast.success('Store added successfully!')
      navigate(-1)
    } catch (error) {
      console.error('Error adding store:', error)
    }
  }

  // Function to handle updating an existing store
  const handleUpdateStore = async (values: any) => {
    const FormData = {
      clientEntityId: clientEntity?.clientEntityId,
      entityName: values.locationName,
      entityCode: values.entityCode,
      clientId: values.clientName,
      contactNo: values.clientPhone,
      emailId: values.clientInvoiceEmail,
      address: values.clientAddress,
      city: '',
      state: values.clientState,
      postalCode: values.clientPostCode,
      country: '',
      lat: '',
      long: '',
      fileName: values.fileName,
      pdfName: values.pdfName,
      minNoofWords: values.minWords,
      maxNoofWords: values.maxWords,
      showPom: values.showProductOfMonth,
      extraFields: values.showExtraFields,
      entityTypeId: values.hotelType,
      bccEmailId: values.teamBCCEmail,
      briefJson: values.briefJson,
      ratingJson: values.starRatings,
      maxNoOfImages: values.maxImages,
      minNoOfImages: values.minImages,
      invoicePanel: values.hideInvoicePanel,
      createdBy: 1
    }
    try {
      const response = await ApiPutUpdateClientEntity(FormData)
      console.log('Store updated:', response)
      toast.success('Store Update successfully!')
      navigate(-1)
    } catch (error) {
      console.error('Error updating store:', error)
    }
  }

  const validationSchema = Yup.object({
    locationName: Yup.string().required('Location Name is required'),
    clientPhone: Yup.string()
      .matches(/^\d{10}$/, 'Phone number must be 10 digits')
      .required('Client Phone is required'),
    clientAddress: Yup.string().required('Client Address is required'),
    clientState: Yup.string().required('Client State is required'),
    clientPostCode: Yup.string()
      .matches(/^\d{4,6}$/, 'Postcode must be 4-6 digits')
      .required('Client PostCode is required'),
    clientInvoiceEmail: Yup.string()
      .email('Invalid email address')
      .required('Client Invoice Email is required'),
    fileName: Yup.string().required('File Name is required'),
    pdfName: Yup.string().required('PDF Name is required'),
    templateURL: Yup.string()
      .url('Invalid URL')
      .required('Template URL is required'),
    minWords: Yup.number()
      .min(0, 'Minimum words must be 0 or greater')
      .required('Minimum words are required'),
    maxWords: Yup.number()
      .min(
        Yup.ref('minWords'),
        'Maximum words must be greater than minimum words'
      )
      .required('Maximum words are required'),
    hotelType: Yup.string().required('Hotel Type is required'),
    teamBCCEmail: Yup.string()
      .email('Invalid email address')
      .required('Team BCC Email is required')
  })

  const [briefJsonNew, setBriefJson] = useState<any>([]);

  const handleGetEditType = async () => {
    try {
      const response: any = await ApiGetBriefJson();
      if (Array.isArray(response.data)) {
        const transformedData = response.data.map((item: any) => ({
          value: item.briefJsonId,
          label: item.briefJsonTitle,
        }));
        setBriefJson(transformedData);
      } else {
        console.error('Unexpected data format:', response.data);
        setBriefJson([]); // Reset to empty array on failure
      }
    } catch (error) {
      console.error('Error during API call:', error);
      setBriefJson([]); // Reset to empty array on error
    }
  };
  

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      onSubmit={values => {
        console.log('Form submitted with values:', values)
        if (mode === 'add') {
          console.log('Calling handleAddStore')
          handleAddStore(values) // Call the add method on submit
        } else {
          console.log('Calling handleUpdateStore')
          handleUpdateStore(values) // Call the update method on submit
        }
      }}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form style={{ background: 'white', padding: '30px' }}>
          <h1 className='common-add'>
            {mode === 'add' ? 'Add New Business' : 'Update Business'}
          </h1>

          <div className='menage-store-select'>
            <Autocomplete
              options={clients}
              value={
                clients.find(
                  (option: any) => option.value === formValues.clientName
                ) || null
              } // Handle case where clientName doesn't match any option
              getOptionLabel={(option: any) => option.label}
              onChange={(event, newValue) => {
                if (newValue) {
                  setFormValues({ ...formValues, clientName: newValue.value }) // Store the client ID or the selected value
                } else {
                  setFormValues({ ...formValues, clientName: '' }) // Handle case where no client is selected
                }
              }}
              renderInput={params => (
                <TextField {...params} label='Select Store' />
              )}
              fullWidth
              disabled={mode === 'update'}
            />
            <Autocomplete
              options={entityType} // Use entityType state here
              style={{ marginTop: '15px' }}
              value={
                entityType.find(
                  option => option.value === formValues.hotelType
                ) || null
              }
              getOptionLabel={option => option.label}
              onChange={(event, newValue) => {
                if (newValue) {
                  setFormValues({
                    ...formValues,
                    hotelType: newValue.value
                  })
                } else {
                  setFormValues({ ...formValues, hotelType: '' })
                }
              }}
              renderInput={params => (
                <TextField {...params} label='Select Entity Type' />
              )}
              fullWidth
              disabled={mode === 'update'}
            />
          </div>
          <div style={{ padding: '20px', width: '100%', paddingBottom: '0px' }}>
            {[
              { label: 'Location Name', name: 'locationName', type: 'text' },
              { label: 'Entity Code', name: 'entityCode', type: 'text' },
              // { label: "Client Name", name: "clientName", type: "text" },
              { label: 'Client Phone', name: 'clientPhone', type: 'text' },
              { label: 'Client Address', name: 'clientAddress', type: 'text' },
              { label: 'Client State', name: 'clientState', type: 'text' },
              {
                label: 'Client PostCode',
                name: 'clientPostCode',
                type: 'text'
              },
              {
                label: 'Client Invoice Email',
                name: 'clientInvoiceEmail',
                type: 'email'
              },
              { label: 'File Name', name: 'fileName', type: 'text' },
              { label: 'PDF Name', name: 'pdfName', type: 'text' },
              { label: 'Template URL', name: 'templateURL', type: 'url' },
              { label: 'Min. No. of Words', name: 'minWords', type: 'number' },
              { label: 'Max. No. of Words', name: 'maxWords', type: 'number' },
              {
                label: 'Show Product of Month?',
                name: 'showProductOfMonth',
                type: 'checkbox'
              },
              {
                label: 'Show Extra Fields?',
                name: 'showExtraFields',
                type: 'checkbox'
              },
              // { label: "Hotel Type", name: "hotelType", type: "text" },
              // { label: "Owner Email", name: "ownerEmail", type: "email" },
              { label: 'Team BCC Email', name: 'teamBCCEmail', type: 'email' },
              {
                label: 'Report Message',
                name: 'reportMessage',
                type: 'textarea'
              },
              { label: 'Brief JSON', name: 'briefJson', type: 'Brief' },
              { label: 'Star Ratings', name: 'starRatings', type: 'text' },
              {
                label: 'Max. No. of Images',
                name: 'maxImages',
                type: 'number'
              },
              {
                label: 'Min. No. of Images',
                name: 'minImages',
                type: 'number'
              },
              {
                label: 'Hide Invoice Panel?',
                name: 'hideInvoicePanel',
                type: 'checkbox'
              }
            ].map(({ label, name, type }) => (
              <div
                key={name}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px',
                  width: '100%'
                }}
              >
                <label
                  htmlFor={name}
                  style={{
                    marginRight: '10px',
                    minWidth: '200px',
                    textAlign: 'right'
                  }}
                >
                  {label}:
                </label>
                {type === 'Brief' ? (
                  <div style={{width:"100%"}}>
                  <Autocomplete
                  multiple
                    options={briefJsonNew}
                    // value={
                    //   briefJsonNew.find(option => option.value === values.briefJson) || null
                    // }
                    value={briefJsonNew.filter(editor =>
                      values.briefJson.includes(editor.value)
                    )}
                    getOptionLabel={option => option.label}
                    onChange={(event, newValue) => {
                      const selectedValues = newValue.map(option => option.value); // Extract values from selected options
                      handleChange({ target: { name: 'briefJson', value: selectedValues } }); // Update Formik's value
                    }}
                    
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Select briefJson'
                        fullWidth
                      />
                    )}
                  />
                  </div> 
                ) : type === 'textarea' ? (
                  <Field
                    as='textarea'
                    id={name}
                    name={name}
                    style={{ width: '100%', height: '80px' }}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : type === 'checkbox' ? (
                  <Field
                    type='checkbox'
                    id={name}
                    name={name}
                    style={{ width: 'auto', margin: '0 10px' }}
                    checked={values[name]} // Use checked instead of value
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <Field
                    type={type}
                    id={name}
                    name={name}
                    style={{
                      width: 'calc(100% - 220px)',
                      padding: '5px'
                    }}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
              </div>
            ))}
          </div>
          <div className='text-center'>
            <Button
              style={{ padding: '9px 70px' }}
              type='submit'
              variant='contained'
              color='primary'
              className='table-row-background'
            >
              {mode === 'add' ? 'Add Business' : 'Update Business'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddOrUpdateStore
