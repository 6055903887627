import React, { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ApiGetBriefJson } from '../../apiService/Core/Apicall.tsx'
import axios from 'axios'

const BriefJson: React.FC = () => {
  const [briefs, setBriefs] = useState<any[]>([])
  const navigate = useNavigate()

  const addOrUpdateBriefJson = (id?: any, brief?: any) => {
    debugger
    if (id) {
      navigate(`/admin/add-or-update-briefjson/update/${id}`, {
        state: { brief }
      })
    } else {
      navigate('/admin/add-or-update-briefjson/add')
    }
  }

  const handleDeleteBrief = async (id: number) => {
    try {
      // setBriefs(prev => prev.filter(brief => brief.id !== id))
      toast.success('Brief deleted successfully')
    } catch (error) {
      console.error('Error deleting brief:', error)
      toast.error('Failed to delete brief')
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      await handleApiGetBriefJson()
    }
    fetchData()
    return () => {}
  }, [])

  const handleApiGetBriefJson = async () => {
    try {
      const res = await ApiGetBriefJson()
      setBriefs(res.data)
      console.log('res', res)
    } catch (error) {
      console.error('Error deleting brief:', error)
    }
  }

  return (
    <div className='manage-admin-container'>
      <h1 className='common-manage-h1'>Manage Brief JSON</h1>
      <div className='add-button common-manage-button'>
        <Button
          variant='contained'
          onClick={() => addOrUpdateBriefJson()}
          className='table-row-background'
        >
          Add Brief JSON
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className='table-row-background'>
              <TableCell style={{ color: '#fff' }}>Title</TableCell>
              <TableCell style={{ color: '#fff' }}>Min Word</TableCell>
              <TableCell style={{ color: '#fff' }}>Max Word</TableCell>
              <TableCell style={{ color: '#fff' }}>Edit</TableCell>
              <TableCell style={{ color: '#fff' }}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {briefs.map(brief => (
              <TableRow key={brief.briefJsonId}>
                <TableCell>{brief.briefJsonTitle}</TableCell>
                <TableCell>{brief.minNoOfWords}</TableCell>
                <TableCell>{brief.maxNoOfWords}</TableCell>
                <TableCell>
                  <Button
                    variant='contained'
                    onClick={() =>
                      addOrUpdateBriefJson(brief.briefJsonId, brief)
                    }
                    className='table-row-background'
                  >
                    Edit
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    variant='contained'
                    className='delete-transparent'
                    onClick={() => handleDeleteBrief(brief.briefJsonId)}
                  >
                    <DeleteIcon className='delete-icon' />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default BriefJson
