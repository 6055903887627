import React, { useState, useEffect } from 'react'
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
  Autocomplete,
  TextField,
  Button
} from '@mui/material'
import { Images } from '../../global/GloblImages.tsx'
import HeaderMenu from './HeaderMenu.tsx'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

const Header: React.FC = () => {
  const [selectedRegion, setSelectedRegion] = useState<string | null>('')
  const [userRole, setUserRole] = useState<number | null>(null)

  const navigate = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [regionData, setRegionData] = useState<any>(['United States', 'Europe', 'Asia', 'Africa', 'Oceania'])
  useEffect(() => {
    const role = localStorage.getItem('userRole')
    if (role) setUserRole(Number(role))
  }, [])
 
  const handleRegionChange = (_: any, value: string | null) =>
    setSelectedRegion(value)

  const handleProfileClick = () => console.log('Profile button clicked!')

  const handleLogout = () => {
    localStorage.removeItem('userRole')
    localStorage.removeItem('userToken')
    navigate('/login')
  }
  const icons: any = Images()

  return (
    <div>
    <ToastContainer />
    <AppBar
      position='sticky'
      className='custom-app-bar'
      sx={{ marginTop: isMobile ? '0' : '20px' }}
    >
      <Toolbar style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            padding: '5px',
            marginRight: 'auto',
            justifyContent: 'center',
            marginTop: '-15px',
            marginBottom: '-15px',
            boxShadow: '0 1px 2px -1px #0c0c0c1a, 0 1px 3px #0c0c0c1a ',
            borderRadius: '10px'
          }}
        >
          <img
            className='Profile_Images'
            src={icons.mmLogo}
            alt='Logo'
            style={{ height: '100px', maxWidth: '100%' }}
          />
        </div>
        <HeaderMenu userRole={userRole} />
        {/* <Autocomplete
          value={selectedRegion}
          className='Autocomplete_Bar'
          onChange={handleRegionChange}
          options={regionData}
          renderInput={params => (
            <TextField
              {...params}
              label='Select Region'
              variant='outlined'
              className='Region_bar'
              style={{
                marginLeft: '20px',
                minWidth: 180
              }}
              InputLabelProps={{
                style: { color: 'white', fontSize: '0.85rem' }
              }}
              InputProps={{
                ...params.InputProps,
                style: { color: 'white', fontSize: '0.85rem' }
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': { borderColor: 'white' },
                  '&.Mui-focused fieldset': { borderColor: 'white' },
                  '& fieldset': { borderWidth: '1px', borderColor: 'white' }
                },
                '& .MuiInputLabel-root.Mui-focused': { color: 'white' }
              }}
            />
          )}
        /> */}
        <Button
          variant='contained'
          // className="Profile_Button"
          onClick={handleProfileClick}
          sx={{
            marginLeft: '40px',
            backgroundColor: 'transparent'

            // backgroundColor: "#6e5b37",
            // color: "white",
            // "&:hover": { backgroundColor: "#5a4c2d" },
            // display: "flex",
            // alignItems: "center",
            // padding: "8px 16px",
          }}
        >
          <AccountCircleIcon />
          {/* Profile */}
        </Button>
        <Button
          variant='contained'
          onClick={handleLogout}
          sx={{
            marginLeft: '20px',
            backgroundColor: 'transparent',
            color: 'white',
            '&:hover': { backgroundColor: 'transparent' },
            display: 'flex',
            justifyContent: 'center',
            padding: '8px',
            minWidth: 'auto',
            borderRadius: '50%'
          }}
        >
          <ExitToAppIcon />
        </Button>
      </Toolbar>
    </AppBar>
    </div>
  )
}

export default Header
