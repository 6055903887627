import './bootstrap-icons.css'
import { useEffect } from 'react'
import { loadDomainSpecificCSS } from './global/GlobalCss.tsx'
import './App.css'
import React from 'react'
import AppRoutes from './Routes/AppRoutes.tsx'
import { useSelector } from "react-redux";


function App () {
  const isLoading = useSelector((store:any) => store.isLoading);
  useEffect(() => {
    loadDomainSpecificCSS()
  })
  return (
    <div className='App'>
      <AppRoutes />
      {isLoading && (
        <div className='loader-overlay'>
          <div className='spinner'>
            <div className='clip-loader'></div>
          </div>
        </div>
      )}
    </div>
  )
}
export default App
