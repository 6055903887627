import React from 'react';

const VenueAdminDashboard: React.FC = () => {
  return (
    
    <div className='Admin_Dashboard'>
      <h1>VenueAdmin Dashboard</h1>
    </div>
  );
};

export default VenueAdminDashboard;
