
import { combineReducers } from "redux";
import loadingReducer from "./loadingReducer.ts";
import redirectreducer from "./redirectreducer.ts"

const rootReducer = combineReducers({
  isLoading: loadingReducer,
  redirect:redirectreducer,
});

export default rootReducer;
