import { Button, TextField } from "@mui/material";
import React from "react";

const ImportExportQuestions: React.FC = () => {
  return (
    <div className="manage-admin-container">
      <h1 className="common-manage-h1">Import Export Questions</h1>

      <div style={{ display: "flex", gap: "20px", padding: "10px" }}>
        <div
          style={{
            margin: "10px",
            padding: "10px",
            border: "none", // No border
            borderRadius: "5px", // Optional rounded corners
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Box shadow for depth
            textAlign: "center",
          }}
        >
          <h5>Export All Questions</h5>
          <Button
            variant="contained"
            className="table-row-background"
            style={{ margin: "10px" }}
          >
            Export All
          </Button>
        </div>
        <div
          style={{
            margin: "10px",
            padding: "10px",
            border: "none", // No border
            borderRadius: "5px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Box shadow for depth
            textAlign: "center",
          }}
        >
          <h5>Import Questions</h5>
          <TextField
            type="file"
            variant="outlined"
            style={{ margin: "10px" }}
          />
          <Button
            variant="contained"
            className="table-row-background"
            style={{ margin: "10px" }}
          >
            Upload
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImportExportQuestions;
