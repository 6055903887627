import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Switch
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import MapIcon from '@mui/icons-material/Room'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useNavigate } from 'react-router-dom'
import {
  ApiPutVenueStatus,
  ApiGetVenueList
} from '../../apiService/Core/Apicall.tsx'
const ManageVenue: React.FC = () => {
  const [venues, setVenues] = useState<any[]>([])
  const [status, setStatus] = useState({})

  const navigate = useNavigate()

  const addOrUpdateVenue = (venue?: any) => {
    if (venue) {
      navigate(`/admin/add-or-update-venue/update/${venue?.venueId}`, {
        state: { venueData: venue }
      })
    } else {
      navigate('/admin/add-or-update-venue/add')
    }
  }

  const [isDialogOpen, setDialogOpen] = useState(false)

  const [selectedVenue, setSelectedVenue] = useState<any>(null)
  const handleOpenMap = venue => {
    setSelectedVenue(venue)
    setDialogOpen(true)
  }

  const openMapInNewTab = (venue: any) => {
    const url = '/venue-map?id=1&lat=-25.2744&lng=133.7751'
    window.open(url, '_blank') // Open map page in a new tab
  }

  // const openMapInNewTab = (venue: any) => {
  //   const url = `https://www.google.com/maps?q=-25.2744,133.7751&z=15`;
  //   window.open(url, "_blank"); // Open map page in a new tab
  // };

  useEffect(() => {
    const fetchData = async () => {
      await handleGetVenueList()
    }
    fetchData()
    return () => {}
  }, [])

  const handleGetVenueList = async () => {
    const data = '?offset=0&limit=100'
    try {
      const response: any = await ApiGetVenueList(data)
      setVenues(response.data)
      console.log('venue list', response.data)
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  const handleChange = async (venue: any, newStatus: string) => {
    setStatus(prevStatus => ({
      ...prevStatus,
      [venue.venueId]: newStatus
    }))

    setVenues(prevList =>
      prevList.map(item =>
        item.venueId === venue.venueId
          ? {
              ...item,
              venueStatus: {
                ...item.userStatus,
                userStatus: newStatus
              }
            }
          : item
      )
    )

    try {
      const body = {
        venueId: venue?.venueId,
        status: newStatus
      }
      const response: any = await ApiPutVenueStatus(body)
      console.log('Status updated successfully:', response)
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }
  return (
    <div className='manage-admin-container'>
      <h1 className='common-manage-h1'>Manage Sites</h1>
      <div className='add-button common-manage-button'>
        <Button
          variant='contained'
          onClick={() => addOrUpdateVenue()}
          className='table-row-background '
        >
          Add New Site
        </Button>
        {/* <Button
          variant='contained'
          onClick={() => addOrUpdateVenue()}
          className='table-row-background '
          style={{ marginLeft: '10px' }}
        >
          Add New Report
        </Button> */}
      </div>
      <Table>
        <TableHead>
          <TableRow className='table-row-background'>
            <TableCell style={{ color: '#fff' }}></TableCell>
            <TableCell style={{ color: '#fff' }}>XLS</TableCell>
            <TableCell style={{ color: '#fff' }}>Venue Name</TableCell>
            <TableCell style={{ color: '#fff' }}>Venue Code</TableCell>
            <TableCell style={{ color: '#fff' }}>State</TableCell>
            <TableCell style={{ color: '#fff' }}>Map</TableCell>
            {/* <TableCell style={{ color: "#fff" }}>Invoice</TableCell>  */}
            <TableCell style={{ color: '#fff' }}>Active</TableCell>
            <TableCell style={{ color: '#fff' }}>Copy Brief</TableCell>
            <TableCell style={{ color: '#fff' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {venues.map(venue => (
            <TableRow key={venue.id}>
              <TableCell>
                <Button variant='contained' className='delete-transparent'>
                  <DeleteIcon className='delete-icon' />
                </Button>
              </TableCell>
              <TableCell>
                <Button variant='contained' className='table-row-background'>
                  {' '}
                  Upload
                </Button>
              </TableCell>
              <TableCell>{venue.venueName}</TableCell>
              <TableCell>{venue.venueCode}</TableCell>
              <TableCell>{venue.state}</TableCell>
              <TableCell>
                <Button
                  variant='contained'
                  startIcon={<MapIcon />}
                  className='table-row-background'
                  onClick={() => handleOpenMap(venue)}
                >
                  Set
                </Button>
              </TableCell>
              {/* <TableCell></TableCell> */}
              <TableCell>
                <div className='switch-admin'>
                  <Switch
                    checked={venue?.venueStatus?.userStatus === 'active'}
                    onChange={e => {
                      const newStatus = e.target.checked ? 'active' : 'inActive'
                      handleChange(venue, newStatus) // Pass the store and the new status
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />{' '}
                </div>
              </TableCell>
              <TableCell>
                <Button
                  variant='contained'
                  startIcon={<ContentCopyIcon />}
                  className='table-row-background'
                ></Button>
              </TableCell>
              <TableCell>
                <Button
                  variant='contained'
                  className='table-row-background'
                  onClick={() => addOrUpdateVenue(venue)}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* <GoogleMapDialog
        isOpen={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        lat={selectedVenue?.lat}
        lng={selectedVenue?.lng}
      /> */}
    </div>
  )
}

export default ManageVenue
