import React, { useEffect, useRef, useState, MouseEvent } from 'react'
import {
  Button,
  Drawer,
  Divider,
  IconButton,
  Popover,
  useMediaQuery,
  useTheme
} from '@mui/material'
import {
  Menu as MenuIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon
} from '@mui/icons-material'
import MenuDataCommon from './MenuItems/CommonMenuItems/menuItems.json'
import AldiMenuData from './MenuItems/AldiMenuItems/AldiMenuItems.json'
import Domains from '../../Constants/domain.ts'
import { useNavigate } from 'react-router-dom'

interface MenuItem {
  label: string
  id: string
  path?: string
  subMenu?: MenuItem[]
  roleIds?: number[]
}
interface HeaderMenuProps {
  userRole: number | null
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({ userRole }) => {
  const [menuData, setMenuData] = useState<MenuItem[]>([])
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: HTMLElement | null
  }>({})

  const navigate = useNavigate();

  useEffect(() => {
    const domain = window.location.hostname
    if (domain.includes(Domains.ALDI)) {
      setMenuData(AldiMenuData)
    } else {
      setMenuData(MenuDataCommon)
    }
  }, [])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const toggleDrawer = (open: boolean) => setDrawerOpen(open)

  const buttonRefs = useRef<{ [key: string]: HTMLButtonElement | null }>({})

  const handleMenuClick = (
    menuId: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(prev => ({
      ...prev,
      [menuId]: buttonRefs.current[menuId]
    }))
  }
  const handleCloseMenu = (menuId: string) => {
    setAnchorEl(prev => ({ ...prev, [menuId]: null }))
    console.log(`Menu item clicked:${menuId}`)
  }

  const handleAction = (id: string, path?: string) => {
    console.log(`Menu item clicked: ${id}, Navigate to: ${path}`)
    setAnchorEl({});
    if (path) {
      navigate(path);
    } else {
        console.error('Path is undefined');
    }
  }

  const renderSubMenu = (menuItems: MenuItem[], menuId: string) => (
    <div style={{ backgroundColor: '#fff', minWidth: 200 }}>
      {menuItems.map(item => {
        if (item.roleIds && !item.roleIds.includes(userRole!)) return null

        return (
          <div
            key={item.id}
            style={{
              padding: '10px 20px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer'
            }}
            ref={(el: any) => (buttonRefs.current[item.id] = el)}

            onClick={
              item.subMenu ? undefined : () => handleAction(item.id, item.path)
            }
            onMouseEnter={e =>
              item.subMenu && handleMenuClick(item.id, e as any)
            }
            onMouseLeave={() => item.subMenu && handleCloseMenu(item.id)}
          >
            {item.label}
            {item.subMenu && <ArrowRightIcon style={{ marginLeft: '8px' }} />}
            {item.subMenu && (
              <Popover
                open={Boolean(anchorEl[item.id])}
                anchorEl={anchorEl[item.id]}
                onClose={() => handleCloseMenu(item.id)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                {/* <div
                      className='close_menu_Popover'
                      onMouseOver={() => handleCloseMenu(item.id)}
                    ></div> */}
                {renderSubMenu(item.subMenu, item.id)}
              </Popover>
            )}
          </div>
        )
      })}
    </div>
  )

  return (
    <>
      {isMobile && (
        <IconButton color='inherit' onClick={() => toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
      )}
      <div
        className='ShowDiv hidden_rl'
        style={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}
      >
        {menuData.map(item => {
          if (item.roleIds && !item.roleIds.includes(userRole!)) return null

          return (
            <div key={item.id}>
              <div>
                <Button
                  ref={(el: any) => (buttonRefs.current[item.id] = el)}
                  className='header_menu_button'
                  variant='text'
                  style={{
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    transition: 'background-color 0.3s ease'
                  }}
                  onClick={
                    item.subMenu ? undefined : () => handleAction(item.id)
                  }
                  onMouseEnter={e =>
                    item.subMenu && handleMenuClick(item.id, e as any)
                  }
                >
                  {item.label}
                  {item.subMenu && (
                    <ArrowDropDownIcon style={{ marginLeft: '8px' }} />
                  )}
                </Button>
                {item.subMenu && (
                  <Popover
                    open={Boolean(anchorEl[item.id])}
                    anchorEl={anchorEl[item.id]}
                    onClose={() => handleCloseMenu(item.id)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                  >
                    <div
                      className='close_menu_Popover'
                      onMouseOver={() => handleCloseMenu(item.id)}
                    ></div>
                    {renderSubMenu(item.subMenu, item.id)}
                  </Popover>
                )}
              </div>
            </div>
          )
        })}
      </div>

      <Drawer
        anchor='right'
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <div
          className=' Phone_menu'
          style={{
            width: 250,
            padding: '20px',
            backgroundColor: '#333',
            height: 'fit-content'
          }}
        >
          {menuData.map(item => {
            if (item.roleIds && !item.roleIds.includes(userRole!)) return null
            return (
              <div key={item.id}>
                <Button
                  fullWidth
                  variant='text'
                  style={{
                    color: '#fff',
                    marginBottom: '10px',
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  onClick={
                    item.subMenu ? undefined : () => handleAction(item.id)
                  }
                  onMouseEnter={e =>
                    item.subMenu && handleMenuClick(item.id, e as any)
                  }
                >
                  {item.label}
                  {item.subMenu && (
                    <ArrowDropDownIcon
                      style={{ marginLeft: '8px' }}
                      onClick={(e: any) => handleMenuClick(item.id, e)}
                    />
                  )}
                </Button>
              </div>
            )
          })}
          <div
            className='footer_Autocomplete'
            style={{ display: 'none' }}
          ></div>
          <Divider style={{ backgroundColor: '#fff' }} />
        </div>
      </Drawer>
    </>
  )
}

export default HeaderMenu
