import React, { useEffect, useRef, useState } from 'react'
import GoogleMap from 'google-maps-react-markers'

interface AppProps {
  lat: number // Changed to just number, no need for { lat: number; } object
  lng: number // Changed to just number
  setLat: (lat: number) => void
  setLng: (lng: number) => void
}

const App: React.FC<AppProps> = ({ setLat, lat, lng, setLng }) => {
  const mapRef = useRef<google.maps.Map | null>(null)
  const [mapReady, setMapReady] = useState(false)
  const [searchBox, setSearchBox] =
    useState<google.maps.places.SearchBox | null>(null)

  const [marker, setMarker] = useState<google.maps.Marker | null>(null)

  const onGoogleApiLoaded = ({
    map,
    maps
  }: {
    map: google.maps.Map
    maps: typeof google.maps
  }) => {
    mapRef.current = map
    setMapReady(true)

    const initialMarker = new maps.Marker({
      position: { lat, lng },
      map,
      title: 'Default Location'
    })
    setMarker(initialMarker)

    const input = document.getElementById('search-input') as HTMLInputElement
    const searchBoxInstance = new maps.places.SearchBox(input)
    setSearchBox(searchBoxInstance)

    searchBoxInstance.addListener('places_changed', () => {
      const places = searchBoxInstance.getPlaces()
      if (places && places.length > 0) {
        const place = places[0]
        const location = place.geometry?.location

        if (location) {
          const searchedLat = location.lat()
          const searchedLng = location.lng()

          setLat(searchedLat)
          setLng(searchedLng)
          map.setCenter({ lat: searchedLat, lng: searchedLng })

          if (marker) {
            marker.setPosition(location)
          } else {
            const newMarker = new maps.Marker({
              position: location,
              map,
              title: place.name
            })
            setMarker(newMarker)
          }
        }
      }
    })

    map.addListener('click', (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        const clickedLat = event.latLng.lat()
        const clickedLng = event.latLng.lng()

        setLat(clickedLat)
        setLng(clickedLng)

        if (marker) {
          marker.setPosition(event.latLng)
        } else {
          const newMarker = new maps.Marker({
            position: event.latLng,
            map
          })
          setMarker(newMarker)
        }
      }
    })
  }

  return (
    <>
      <div style={{width:"100%",position:"relative"}}>
        <input id='search-input' type='text' placeholder='Search location' />
        <GoogleMap
          apiKey='AIzaSyCmcI0swO1MNjQYUD7qktNT78-cDMBoi44'
          defaultCenter={{ lat, lng }} 
          defaultZoom={10}
          mapMinHeight='100vh'
          onGoogleApiLoaded={onGoogleApiLoaded}
        />
      </div>
    </>
  )
}

export default App
