import {
  usersLogin,
  getAllRoles,
  getAdminList,
  getClientEntities,
  getUserDetails,
  postCreateAdmin,
  putUpdateClient,
  postCreateClient,
  getClients,
  putDeleteClient,
  putStoreStatus,
  putUpdateClientEntity,
  postCreateClientEntity,
  getEntityTypeList,
  getVenueList,
  putVenueStatus,
  postCreateVenue,
  putUpdateVenue,
  putUpdateAdminDetails,
  putUpdateAdminRole,
  putUpdateAdminStatus,
  getShopType,
  getStarRating,
  getBriefJson,
  putUpdateEditors
} from './ApiEndPoint.tsx'
import { baseApiCall } from './BaseApiCall.tsx'

export const ApiUsersLogin = (data: any) => {
  return baseApiCall({
    url: usersLogin,
    method: 'post',
    data
  })
}

export const ApiGetAllRoles = (data: any) => {
  return baseApiCall({
    url: getAllRoles,
    method: 'get',
    data
  })
}
export const ApiGetAdminList = (data: any) => {
  return baseApiCall({
    url: getAdminList + data,
    method: 'get',
  })
}
export const ApiGetClientEntities = (data: any) => {
  return baseApiCall({
    url: getClientEntities,
    method: 'get',
    data
  })
}

export const ApiGetUserDetails = (id: any) => {
  return baseApiCall({
    url: getUserDetails + id,
    method: 'get'
  })
}
export const ApiPostCreateAdmin = (data: any) => {
  return baseApiCall({
    url: postCreateAdmin,
    method: 'post',
    data
  })
}
export const ApiPutUpdateClient = (data: any) => {
  return baseApiCall({
    url: putUpdateClient,
    method: 'put',
    data
  })
}

export const ApiPostCreateClient = (data: any) => {
  return baseApiCall({
    url: postCreateClient,
    method: 'post',
    data
  })
}
export const ApiGetClients = () => {
  return baseApiCall({
    url: getClients,
    method: 'get'
  })
}
export const ApiPutDeleteClient = (clientId: any) => {
  return baseApiCall({
    url: putDeleteClient + clientId,
    method: 'put'
  })
}
export const ApiPutStoreStatus = (data: any) => {
  return baseApiCall({
    url: putStoreStatus,
    method: 'put',
    data
  })
}
export const ApiPutUpdateClientEntity = (data: any) => {
  return baseApiCall({
    url: putUpdateClientEntity,
    method: 'put',
    data
  })
}
export const ApiPostCreateClientEntity = (data: any) => {
  return baseApiCall({
    url: postCreateClientEntity,
    method: 'post',
    data
  })
}

export const ApiGetEntityTypeList = () => {
  return baseApiCall({
    url: getEntityTypeList,
    method: 'get'
  })
}

export const ApiGetVenueList = (data: any) => {
  return baseApiCall({
    url: getVenueList + data,
    method: 'get'
  })
}
export const ApiPutVenueStatus = (data: any) => {
  return baseApiCall({
    url: putVenueStatus,
    method: 'put',
    data
  })
}

export const ApiPostCreateVenue = (data: any) => {
  return baseApiCall({
    url: postCreateVenue,
    method: 'post',
    data
  })
}
export const ApiPutUpdateVenue = (data: any) => {
  return baseApiCall({
    url: putUpdateVenue,
    method: 'put',
    data
  })
}

export const ApiPutUpdateAdminDetails = (data: any) => {
  return baseApiCall({
    url: putUpdateAdminDetails,
    method: 'put',
    data
  })
}

export const ApiPutUpdateAdminRole = (data: any) => {
  return baseApiCall({
    url: putUpdateAdminRole,
    method: 'put',
    data
  })
}

export const ApiPutUpdateAdminStatus = (data: any) => {
  return baseApiCall({
    url: putUpdateAdminStatus,
    method: 'put',
    data
  })
}

export const ApiGetShopType =  () => {
  return baseApiCall({
    url: getShopType,
    method: 'get',
  })
}

export const ApiGetStarRating = () =>{
  return baseApiCall({
    url: getStarRating,
    method: 'get',
  })
}
export const ApiGetBriefJson = () =>{
  return baseApiCall({
    url: getBriefJson,
    method: 'get',
  })
}
export const ApiPutBriefJson = (data:any) =>{
  return baseApiCall({
    url: getBriefJson,
    method: 'put',
    data
  })
}

export const ApiPostBriefJson = (data:any) =>{
  return baseApiCall({
    url: getBriefJson,
    method: 'post',
    data
  })
}

export const ApiPutUpdateEditors = (data:any) =>{
  return baseApiCall({
    url: putUpdateEditors,
    method: 'put',
    data
  })
}

