import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  allowedRoles: string[];
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedRoles, children }) => {
  const userRole = localStorage.getItem("userRole");

  return allowedRoles.includes(userRole || "") ? (
    <>{children}</>
  ) : (
    <Navigate to="/unauthorized" replace />
  );
};

export default ProtectedRoute;
