import apiClient from './apiClient.tsx';  // Ensure correct import

// Generic GET method
export const get = async (url) => {
  try {
    const response = await apiClient.get(url);
    return response.data;
  } catch (error) {
    console.error(`GET ${url} failed:`, error.message || error);
    throw error;
  }
};

export const post = async (url, data) => {
  try {
    const response = await apiClient.post(url, data);
    return response.data;
  } catch (error) {
    console.error(`POST ${url} failed:`, error.message || error);
    throw error;
  }
};

export const put = async (url, data) => {
  try {
    const response = await apiClient.put(url, data);
    return response.data;
  } catch (error) {
    console.error(`PUT ${url} failed:`, error.message || error);
    throw error;
  }
};

// Generic DELETE method
export const del = async (url) => {
  try {
    const response = await apiClient.delete(url);
    return response.data;
  } catch (error) {
    console.error(`DELETE ${url} failed:`, error.message || error);
    throw error;
  }
};
