import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import { Autocomplete, Button, TextField } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import PatchedReactQuill from './PatchedReactQuill.tsx'
import {
  ApiGetClientEntities,
  ApiGetEntityTypeList,
  ApiPostCreateVenue,
  ApiPutUpdateVenue,
  ApiGetShopType,
  ApiGetStarRating,
  ApiGetVenueList
} from '../../apiService/Core/Apicall.tsx'
import GoogleMapDialog from './GoogleMapDialog.tsx'
interface AddOrUpdateVenueProps {
  initialValues?: {
    venueName: string
    venueCode: string
    entityName: string
    hotelType: string
    territory: string
    contact: string
    address: string
    suburb: string
    state: string
    country: string
    postCode: string
    phone: string
    fax: string
    email: string
    feedbackEmail: string
    auditEmail: string
    fixItEmail: string
    reportEmail: string
    description: string
    shopType: string
    email2: string
    jobType: string
    tradingHours: string
    website: string
    venuePrice: string
    venuePriceDisplay: string
    pdfDescription: string
    briefJson: string
    lat: number
    long: number
    starRatings: string
    venueLogo: string | File // Added venueLogo to the initial values
  }
  mode: 'add' | 'update'
  onSubmit: (values: any) => void
}

const AddOrUpdateVenue: React.FC<AddOrUpdateVenueProps> = ({
  initialValues = {
    venueName: '',
    venueCode: '',
    entityName: '',
    hotelType: '',
    territory: '',
    contact: '',
    address: '',
    state: '',
    country: '',
    suburb: '',
    postCode: '',
    phone: '',
    fax: '',
    email: '',
    feedbackEmail: '',
    auditEmail: '',
    fixItEmail: '',
    reportEmail: '',
    description: '',
    shopType: '',
    email2: '',
    jobType: '',
    tradingHours: '',
    website: '',
    venuePrice: '',
    venuePriceDisplay: '',
    pdfDescription: '',
    briefJson: '',
    lat: '',
    long: '',
    starRatings: '',
    venueLogo: '' // Default value for venueLogo
  },
  onSubmit
}) => {
  const { mode, id } = useParams<{ mode: 'add' | 'update'; id?: string }>()

  const location = useLocation()
  const venue = location?.state?.venueData
  const [formValues, setFormValues] = useState<any>(initialValues)
  const [clientEntity, setClientEntity] = useState([])
  const [entityType, setEntityType] = useState<any[]>([])
  const [editorValue, setEditorValue] = useState<any>('')
  const [preview, setPreview] = useState<string | null>(null)
  const Latitude: any = Number(venue?.lat)
  const Longitude = Number(venue?.long)
  const [latNew, setLat] = useState<number>(Latitude || 0)
  const [lngNew, setLng] = useState<number>(Longitude || 0)
  const [starRatingList, setStarRating] = useState<any>([])
  const navigate = useNavigate()

  useEffect(() => {
    setFormValues(prevValues => ({
      ...prevValues,
      lat: latNew
    }));
    setFormValues(prevValues => ({
      ...prevValues,
      long: lngNew
    }));
  }, [lngNew]); 
  

  useEffect(() => {
    if (mode === 'update' && venue) {
      setEditorValue(venue.venueDescription || '')
      setFormValues({
        venueName: venue.venueName || '',
        venueCode: venue.venueCode || '',
        entityName: venue.clientEntityId.clientEntityId || '',
        contact: venue.contactNo || '',
        address: venue.address || '',
        city: venue.city || '',
        state: venue.state || '',
        postalCode: venue.postalCode || '',
        email: venue.emailId || '',
        country: venue.country || '',
        lat: latNew || '',
        long: lngNew || '',
        briefJson: venue.briefJson || '',
        starRatings: venue.starRating || '',
        feedbackEmail: venue.feedbackEmail || '',
        auditEmail: venue.auditEmail || '',
        fixItEmail: venue.fixItEmail || '',
        reportEmail: venue.reportEmail || '',
        venueDescription: venue.venueDescription || '',
        tradingHours: venue.tradingHours || '',
        website: venue.websiteURL || '',
        venuePrice: venue.venuePrice || '',
        venuePriceDisplay: venue.venuePriceDisplay || '',
        pdfDescription: venue.pdfDescription || '',
        hotelType: venue?.shopTypeId?.shopTypeId || ''
      })
    }
  }, [mode, id, lngNew, latNew])

  useEffect(() => {
    if (venue) {
      console.log('response getUserDetails', venue?.venueName)
    }
    handleGetApiCallClientEntity()
    handleGetApiCallEntityType()
    handelApiGetStarRating()
    // handleGetVenueList()
  }, [venue, mode])
  const handelApiGetStarRating = async () => {
    try {
      const response: any = await ApiGetStarRating()
      console.log('StarRating list', response.data)
      setStarRating(
        response.data.map((starRating: any) => ({
          label: starRating.starRatingDesc,
          value: starRating.starRatingId
        }))
      )
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  const handleGetApiCallClientEntity = async () => {
    const data = {}
    try {
      const response: any = await ApiGetClientEntities(data)
      console.log('clientEntity list', response.data)
      setClientEntity(
        response.data.map((clientEntity: any) => ({
          label: clientEntity.entityName,
          value: clientEntity.clientEntityId
        }))
      )
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  const handleGetApiCallEntityType = async () => {
    try {
      const response: any = await ApiGetShopType()
      console.log('response roles', response.data)
      const transformedData = response.data.map((item: any) => ({
        value: item.shopTypeId,
        label: item.shopTypeName
      }))
      setEntityType(transformedData)
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  const handleAddVenue = async (values: any) => {
    const valueNew = Array.isArray(values.starRatings)
      ? values.starRatings.map(item => item)
      : []
    const commaSeparated = valueNew.join(',')
    const FormData = {
      venueCode: values.venueCode,
      venueName: values.venueName,
      clientEntityId: values.entityName,
      contactNo: values.contact,
      emailId: values.email,
      address: values.address,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      country: values.country,
      lat: values.lat,
      long: values.long,
      feedbackEmail: values.feedbackEmail,
      auditEmail: values.auditEmail,
      fixItEmail: values.fixItEmail,
      reportEmail: values.reportEmail,
      venueDescription: editorValue,
      shopTypeIds: values.hotelType,
      tradingHours: values.tradingHours,
      websiteURL: values.website,
      venuePrice: values.venuePrice,
      venuePriceDisplay: values.venuePriceDisplay,
      pdfDescription: values.pdfDescription,
      briefJson: values.briefJson,
      logoUrl: null,
      starRating: commaSeparated,
      createdBy: 11
    }
    try {
      console.log(FormData)
      const response = await ApiPostCreateVenue(FormData)
      console.log('New store added:', response)
      toast.success('Venue added successfully!')
      navigate(-1)
    } catch (error) {
      console.error('Error adding Venue:', error)
    }
  }

  const handleUpdateVenue = async (values: any) => {
    const valueNew = Array.isArray(values.starRatings)
      ? values.starRatings.map(item => item)
      : []
    const commaSeparated = valueNew.join(',')

    const FormData = {
      venueId: venue.venueId,
      venueCode: values.venueCode,
      venueName: values.venueName,
      clientEntityId: values.entityName,
      contactNo: values.contact,
      emailId: values.email,
      address: values.address,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode,
      country: values.country,
      lat: values.lat,
      long: values.long,
      feedbackEmail: values.feedbackEmail,
      auditEmail: values.auditEmail,
      fixItEmail: values.fixItEmail,
      reportEmail: values.reportEmail,
      venueDescription: editorValue,
      shopTypeIds: values.hotelType,
      tradingHours: values.tradingHours,
      websiteURL: values.website,
      venuePrice: values.venuePrice,
      venuePriceDisplay: values.venuePriceDisplay,
      pdfDescription: values.pdfDescription,
      briefJson: values.briefJson,
      logoUrl: null,
      starRating: commaSeparated,
      createdBy: 11,
      updatedBy: 11
    }
    console.log('new FormData', FormData)
    try {
      const response = await ApiPutUpdateVenue(FormData)
      console.log('New Venue updated:', response)
      toast.success('Venue added successfully!')
      navigate(-1)
    } catch (error) {
      console.error('Error adding Venue:', error)
    }
  }

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      onSubmit={values => {
        console.log('Form submitted with values:', values)
        if (mode === 'add') {
          console.log('Calling handleAddStore')
          handleAddVenue(values) // Call the add method on submit
        } else {
          console.log('Calling handleUpdateStore')
          handleUpdateVenue(values) // Call the update method on submit
        }
      }}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form style={{ background: 'white', padding: '30px' }}>
          <h1 className='common-add'>
            {mode === 'add' ? 'Add New Site' : 'Update Site'}
          </h1>
          <div className='menage-store-select'>
            <Autocomplete
              options={clientEntity}
              value={
                clientEntity.find(
                  (option: any) => option.value === formValues.entityName
                ) || null
              } // Handle case where clientName doesn't match any option
              getOptionLabel={(option: any) => option.label}
              onChange={(event, newValue) => {
                if (newValue) {
                  setFormValues({ ...formValues, entityName: newValue.value }) // Store the client ID or the selected value
                } else {
                  setFormValues({ ...formValues, entityName: '' })
                }
              }}
              renderInput={params => (
                <TextField {...params} label='Select Store' />
              )}
              fullWidth
              disabled={mode === 'update'}
            />
            <Autocomplete
              options={entityType} // Use entityType state here
              style={{ marginTop: '15px' }}
              value={
                entityType.find(
                  option => option.value === formValues.hotelType
                ) || null
              }
              getOptionLabel={option => option.label}
              onChange={(event, newValue) => {
                if (newValue) {
                  setFormValues({
                    ...formValues,
                    hotelType: newValue.value
                  })
                } else {
                  setFormValues({ ...formValues, hotelType: '' })
                }
              }}
              renderInput={params => (
                <TextField {...params} label='Select Shop Type' />
              )}
              fullWidth
              disabled={mode === 'update'}
            />

            {/* <Autocomplete
              multiple
              id='star-ratings-multiple'
              options={entityType} 
              style={{ marginTop: '15px' }}
              value={entityType.filter(option =>
                formValues.hotelType?.includes(option.value)
              )}

              getOptionLabel={option => option.label}
              onChange={(event, newValues) => {
                if (newValues.length > 0) {
                  setFormValues({
                    ...formValues,
                    hotelType: newValues.map(item => item.value)
                  })
                } else {
                  setFormValues({ ...formValues, hotelType: [] })
                }
              }}
              renderInput={params => (
                <TextField {...params} label='Select Shop Type' />
              )}
              fullWidth
              disabled={mode === 'update'}
            /> */}
          </div>
          <div style={{ padding: '20px', width: '100%', paddingBottom: '0px' }}>
            {[
              { label: 'Venue Name', name: 'venueName', type: 'text' },
              { label: 'Venue Code', name: 'venueCode', type: 'text' },
              { label: 'Contact', name: 'contact', type: 'text' },
              { label: 'Address', name: 'address', type: 'text' },
              { label: 'City', name: 'city', type: 'text' },
              { label: 'State', name: 'state', type: 'text' },
              { label: 'Post Code', name: 'postalCode', type: 'text' },
              { label: 'Country', name: 'country', type: 'text' },
              { label: 'Email', name: 'email', type: 'email' },
              { label: 'Feedback Email', name: 'feedbackEmail', type: 'email' },
              { label: 'Audit Email', name: 'auditEmail', type: 'email' },
              { label: 'Fix It Email', name: 'fixItEmail', type: 'email' },
              { label: 'Report Email', name: 'reportEmail', type: 'email' },
              {
                label: 'Description',
                name: 'venueDescription',
                type: 'Description'
              },
              { label: 'Trading Hours', name: 'tradingHours', type: 'text' },
              { label: 'Website', name: 'website', type: 'url' },
              { label: 'Venue Price', name: 'venuePrice', type: 'number' },
              {
                label: 'Venue Price Display',
                name: 'venuePriceDisplay',
                type: 'text'
              },
              {
                label: 'PDF Description',
                name: 'pdfDescription',
                type: 'textarea'
              },
              { label: 'Brief JSON', name: 'briefJson', type: 'textarea' },
              { label: 'Latitude', name: 'lat', type: 'map' },
              { label: 'Longitude', name: 'long', type: 'map' },
              { label: 'Map', name: '', type: 'googleMap' },

              { label: 'Star Ratings', name: 'starRatings', type: 'string' }
            ].map(({ label, name, type }) => (
              <div
                key={name}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px',
                  width: '100%'
                }}
              >
                <label
                  htmlFor={name}
                  style={{
                    marginRight: '10px',
                    minWidth: '200px',
                    textAlign: 'right'
                  }}
                >
                  {label}:
                </label>
                {type === 'Description' ? (
                  <PatchedReactQuill
                    setEditorValue={setEditorValue}
                    editorValue={editorValue}
                  />
                ) : type === 'textarea' ? (
                  <Field
                    as='textarea'
                    id={name}
                    name={name}
                    style={{ width: '100%', height: '80px' }}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : type === 'checkbox' ? (
                  <Field
                    type='checkbox'
                    id={name}
                    name={name}
                    style={{ width: 'auto', margin: '0 10px' }}
                    checked={values[name]} // Use checked instead of value
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : type === 'map' ? (
                  <Field
                    type={type}
                    id={name}
                    name={name}
                    style={{
                      width: 'calc(100% - 220px)',
                      padding: '5px'
                    }}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : type === 'googleMap' ? (
                  <GoogleMapDialog
                    setLat={setLat}
                    lat={latNew}
                    lng={lngNew}
                    setLng={setLng}
                  />
                ) : type === 'string' ? (
                  <Autocomplete
                    multiple
                    id='star-ratings-multiple'
                    options={starRatingList} // Array of star rating options
                    style={{ marginTop: '15px' }}
                    value={starRatingList.filter(option =>
                      formValues.starRatings?.includes(option.value)
                    )}
                    getOptionLabel={option => option.label}
                    onChange={(event, newValues) => {
                      if (newValues.length > 0) {
                        setFormValues({
                          ...formValues,
                          starRatings: newValues.map(item => item.value)
                        })
                      } else {
                        setFormValues({ ...formValues, starRatings: [] })
                      }
                    }}
                    renderInput={params => (
                      <TextField {...params} label='Select Star Ratings' />
                    )}
                    fullWidth
                    // disabled={mode === 'update'}
                  />
                ) : (
                  <Field
                    type={type}
                    id={name}
                    name={name}
                    style={{
                      width: 'calc(100% - 220px)',
                      padding: '5px'
                    }}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
              </div>
            ))}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '15px',
                width: '100%'
              }}
            >
              <label
                htmlFor='venueLogo'
                style={{
                  marginRight: '10px',
                  minWidth: '200px',
                  textAlign: 'right'
                }}
              >
                Venue Logo:
              </label>
              <input
                type='file'
                accept='image/*'
                onChange={event => {
                  const file = event.target.files ? event.target.files[0] : null
                  if (file) {
                    //setFieldValue('venueLogo', file);
                    const fileReader = new FileReader()
                    fileReader.onloadend = () => {
                      setPreview(fileReader.result as string)
                    }
                    fileReader.readAsDataURL(file)
                  }
                }}
                style={{ width: 'calc(100% - 220px)' }}
              />
            </div>

            {preview && (
              <div style={{ margin: '20px' }} className='text-center'>
                <img
                  src={preview}
                  alt='Venue Logo Preview'
                  style={{
                    maxWidth: '200px',
                    maxHeight: '200px',
                    objectFit: 'contain'
                  }}
                />
              </div>
            )}

            <div className='text-center'>
              <Button
                style={{ padding: '9px 70px' }}
                type='submit'
                variant='contained'
                color='primary'
                className='table-row-background'
              >
                {mode === 'add' ? 'Add Site' : 'Update Site'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddOrUpdateVenue
