import React from 'react';

const AdminDashboard: React.FC = () => {
  return (
    
    <div className='Admin_Dashboard' >
      <h1>Admin Dashboard</h1>
    </div>
  );
};

export default AdminDashboard;
